import { navigate } from "@reach/router";
import Button from "components/Button";
import InputText from "components/InputText";
import Wrapper from "components/Wrapper";
import qs from "querystring";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation } from "urql";
import { FieldLocale } from "utils/common/locale";
import { customerActivateMutation } from "utils/queries/user";
import { routes } from "utils/routes";
import SEO from "components/SEO";
import { useTranslation } from "hooks/useTranslation";

interface Props {
  pageContext: {
    defaultLocale: string;
    allPathLocales: FieldLocale<string>[];
    pagePath: string;
  };
}

/**
 * Customer activate page wrapper
 * Just to allow useContext usage inside
 */
const CustomerActivatePage: React.SFC<Props> = ({ pageContext }) => {
  return <CustomerActivateForm pagePath={pageContext.pagePath} />;
};

/**
 * Handle reset password process
 * This page must be accessed providing appropriate query params
 * otherwise it redirects to home
 */
const CustomerActivateForm = ({ pagePath }: { pagePath: string }) => {
  // State variables
  const [password, setPassword] = useState("");
  const [queryParams, setQueryParams] = useState<any>({});
  const [error, setError] = useState("");
  const { formatMessage } = useTranslation();

  // Mutations
  const [{}, execute] = useMutation(customerActivateMutation);

  // Effects
  useEffect(() => {
    const location = window.location;
    const queryString = location && location.search.replace("?", "");
    const queryParams = qs.parse(queryString);
    if (!queryParams || !queryParams.id || !queryParams.token) {
      navigate(routes.home);
    } else {
      setQueryParams(queryParams);
    }
  }, []);

  /**
   * Execute customer activation
   * In case of error populate the error box
   */
  const handleCustomerActivate = async (e: FormEvent) => {
    e.preventDefault();
    const decodedCustomerId = `gid://shopify/Customer/${queryParams.id}`;
    const encodedCustomerId = btoa(decodedCustomerId);
    const variables = {
      id: encodedCustomerId,
      input: {
        activationToken: queryParams.token,
        password,
      },
    };
    const response = await execute(variables);
    const data = response.data;
    if (data && data.customerActivate.customer) {
      setError("");
      navigate(routes.login);
    } else if (!data || !data.customerActivate.customer || response.error) {
      let errorMessage =
        "We encountered an error while activating your account";
      if (data.customerActivate.customerUserErrors) {
        const errorDetails = data.customerActivate.customerUserErrors
          .map((error: any) => error.message)
          .join("\n");
        errorMessage = errorMessage.concat(`\n${errorDetails}`);
      }
      setError(errorMessage);
    }
  };

  return (
    <Container>
      <Wrapper size="tiny">
        <SEO
          title={formatMessage({ id: "SEO.customerActivate" })}
          link={[
            { rel: "canonical", href: "https://ruedesmille.com" + pagePath },
          ]}
        />
        <form onSubmit={handleCustomerActivate}>
          <Title>Customer account activation</Title>
          <div>
            <InputText
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
            />
          </div>
          {error ? <ErrorBox>{error}</ErrorBox> : null}
          <Button type="submit">Activate account</Button>
        </form>
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 40px;
  ${Button} {
    display: block;
    margin: 0 auto;
  }
`;
const Title = styled.div`
  font-size: 22px;
  color: #977b2b;
  letter-spacing: 0.16em;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const ErrorBox = styled.div`
  color: #dd4f4f;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  padding-bottom: 30px;
  box-sizing: border-box;
  white-space: pre;
  text-align: center;
`;

export default CustomerActivatePage;
